import { Box, Grid, Typography } from "@mui/material";
import {styled} from "@mui/material/styles";

const styles = {
    grid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 2,
        pt: 2,
    },
    title: {
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: 1.25,
        ml: 2,
        display: 'block'
    },
    text: {
        fontSize: "1.25rem",
        fontWeight: 400,
        lineHeight: 1.4,
        ml: 2,
        display: 'block'
    },
};

const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    minHeight: '176px',
}));

const IconBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    background: "#33343B",
    margin: '0 auto',
    width: '100px',
    height: '100px',
}));


const TechnologyItem = ({ item }) => (
    <Grid item sm={12} md={4} lg={3} sx={styles.grid}>
        <StyledBox  >
            <IconBox alignItems={'center'} justifyContent={'center'} sx={{ml:2, mt:'20px'}} display={'flex'}>
                <img src={item.imgSrc}  height={'70px'} width={'70px'} sx={{border: '1px solid red', textAlign: 'center'}}/>
            </IconBox>
            <Box>
                <Typography variant="h4" color={'primary'} mt={'20px'} sx={styles.title} gutterBottom>
                    {item.title}
                </Typography>
                <Typography variant="p" color={'secondary'} mt={'20px'} sx={styles.text}>
                    {item.text}
                </Typography>
            </Box>
        </StyledBox>
    </Grid>
);


export default TechnologyItem;
