import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SectionHeader from "../../components/SectionHeader";

import { motion } from "framer-motion";
import useCustomAnimation from "../hooks/useCustomAnimation";
import NftItem from "../../components/NftItem";

///////////////////////////////////////
const Performance = "/static/features/fomo-1.svg";
const Partnership = "/static/features/fiatmoney-1.svg";
const Subscription = "/static/features/encrypted-1.svg";
const Support = "/static/features/decentralized02-1.svg";

const data = [
    {
        id: 1,
        imgSrc: Performance,
        altText: "Start",
        title: "Start   ",
        text: "Feb 8, 2018 (9:00AM GMT)",
    },
    {
        id: 2,
        imgSrc: Partnership,
        altText: "End",
        title: "End",
        text: "Feb 8, 2018 (9:00AM GMT)",
    },
    {
        id: 3,
        imgSrc: Subscription,
        altText: "Acceptable curencies",
        title: "Acceptable curencies",
        text: "ETH, BTC, LTC",
    },
    {
        id: 4,
        imgSrc: Support,
        altText: "Munber of tokens for sale",
        title: "Munber of tokens for sale",
        text: "900.0000 ICC (9%)",
    },
    {
        id: 5,
        imgSrc: Support,
        altText: "Tokens exchange rate",
        title: "Tokens exchange rate",
        text: "1 ETH = 650 ICC, 1 BTC = 1940 ICC",
    },
    {
        id: 6,
        imgSrc: Support,
        altText: "Minimal transection amount",
        title: "Minimal transection amount",
        text: "1 ETH / 1 BTC / LTC",
    },
    {
        id: 7,
        imgSrc: Support,
        altText: "Minimal transection amount",
        title: "Minimal transection amount",
        text: "1 ETH / 1 BTC / LTC",
    },
    {
        id: 8,
        imgSrc: Support,
        altText: "Minimal transection amount",
        title: "Minimal transection amount",
        text: "1 ETH / 1 BTC / LTC",
    },
];

const RootStyle = styled("section")({
    paddingBottom: "5rem",
});

const SepparatorDiv = styled("div")({
    height: '2px',
    width: '100px',
    background: '#48DCA8',
    margin: '0 auto'
});

const NftBlock = () => {
    return (
        <RootStyle id="nft">
            <Container maxWidth="2xl"  sx={{maxWidth: '1360px'}}>
                <SectionHeader title="Our NFT" />
                <SepparatorDiv></SepparatorDiv>
                <Typography variant={'body2'} color={'secondary'} sx={{textAlign: 'center', maxWidth:'500px', margin: '10px auto auto auto'}}>
                    Some text iobana
                </Typography>
                <Grid container sx={{ mt: 2 }} component={motion.div} spacing={3}  justifyContent={'center'}>
                    {data?.map((item) => (
                        <NftItem key={item.id} item={item} />
                    ))}
                </Grid>
            </Container>
        </RootStyle>
    );
};

export default NftBlock;
