import { Box, Grid, Typography } from "@mui/material";
import {styled} from "@mui/material/styles";

const styles = {
    grid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',
        pb: 2,
        pt: 2,
        width: '100%'
    },
    title: {
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: 1.25,
        ml: 2,
        mr: 2,
        display: 'block'
    },
    text: {
        fontSize: "1.25rem",
        fontWeight: 400,
        lineHeight: 1.2,
        ml: 2,
        mr: 2,
        mb: 2,
        display: 'block'
    },
};

const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    background: "#33343B",
    height: '100%',
    width: '100%'
}));

const TokenDetail = ({ item }) => (
    <Grid item sm={12} md={4} lg={4} sx={styles.grid}>
        <StyledBox display={'flex'} >
            <Box>
                <Typography variant="h4" color={'primary'} mt={'20px'} sx={styles.title} gutterBottom>
                    {item.title}
                </Typography>
                <Typography variant="p" color={'secondary'} mt={'4px'} mb={'20px'} sx={styles.text}>
                    {item.text}
                </Typography>
            </Box>
        </StyledBox>
    </Grid>
);


export default TokenDetail;
