import { styled } from "@mui/material/styles";
import { Link } from "react-scroll";

import Typography from "@mui/material/Typography";
import pixelsToRem from "../styles/Themes/core/PixelsToRem";

const LinkStyle = styled(Link)({
  lineHeight: 1.2,
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: 500,
  color: "white",
  borderTop: '1px solid white',
  borderBottom  : '1px solid white',
  borderRight: '1px solid white',
  borderLeft: '1px solid white',
  minWidth: '120px',
  padding: "10px",
  textAlign: 'center',
  "&:hover": {
    color: "white",
    backgroundColor: 'gray'
  },
  "&.active": {
    color: "white",
    backgroundColor: 'gray'
  },
});

const MenuLink = ({ key, path, label, ...props }) => (
  <LinkStyle
    to={path}
    spy={true}
    smooth={true}
    duration={500}
    sx={{
      borderLeft: path === 'home' ? '1px solid white' : '0px solid white',
    }}
    activeClass="active"
    {...props}
  >
    <Typography variant={'body1'}
    sx={{fontSize: pixelsToRem(15)}}>
      {label}
    </Typography>
  </LinkStyle>
);

export default MenuLink;
