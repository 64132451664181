import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SectionHeader from "../../components/SectionHeader";
import Feature from "../../components/Feature";

import { motion } from "framer-motion";
import useCustomAnimation from "../hooks/useCustomAnimation";

///////////////////////////////////////
const Performance = "/static/features/fomo-1.svg";
const Partnership = "/static/features/fiatmoney-1.svg";
const Subscription = "/static/features/encrypted-1.svg";
const Support = "/static/features/decentralized02-1.svg";

const data = [
  {
    id: 1,
    imgSrc: Performance,
    altText: "Mobile payment made easy",
    title: "Mobile payment made easy",
    text: "There's no need to sign up, you can use a mobile device to pay with the most simple steps",
  },
  {
    id: 2,
    imgSrc: Partnership,
    altText: "Partnership deal",
    title: "Partnership deal",
    text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
  },
  {
    id: 3,
    imgSrc: Subscription,
    altText: "Pro Subscription",
    title: "Pro Subscription",
    text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
  },
    {
      id: 4,
      imgSrc: Support,
      altText: "Customer Support",
      title: "Customer Support",
      text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
    },
];

const RootStyle = styled("section")({
  // minHeight: "50vh",
  // paddingTop: "20rem",
  paddingBottom: "2rem",
});

const SepparatorDiv = styled("div")({
  // minHeight: "50vh",
  // paddingTop: "20rem",
  height: '2px',
  width: '100px',
  background: '#48DCA8',
  margin: '0 auto'
});

const Features = () => {
  const { ref, fadeUp } = useCustomAnimation(0.2);
  return (
    <RootStyle id="features">
      <Container maxWidth="2xl"  ref={ref} sx={{maxWidth:'1360px'}}>
        <SectionHeader title="Why choose our token" />
        <SepparatorDiv></SepparatorDiv>
        <Grid container sx={{ mt: 2 }} animate={fadeUp} component={motion.div} spacing={3}>
          {data?.map((item) => (
            <Feature key={item.id} item={item} />
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
};

export default Features;
