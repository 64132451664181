import { styled } from "@mui/material/styles";


const LinkStyle = styled("a")({
    cursor: "pointer",
    fontWeight: 500,
    color: "white",
    minWidth: '46px',
    minHeight: '46px',
    border: "1px solid white",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover": {
        color: "white",
        backgroundColor: 'gray'
    },
    "&.active": {
        color: "white",
        backgroundColor: 'gray'
    },
});

const ContactsLink = ({ key, path, label, imgPath, ...props }) => (
    <LinkStyle
        href={path}
        {...props}
    >
        <img alt="telegram" src={imgPath} sx={{width:'25px', height: '24px'}}/>
    </LinkStyle>
);

export default ContactsLink;
