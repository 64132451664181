import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
    AppBar,
    Container,
    Toolbar,
    IconButton,
    Box,
    Drawer,
    Button, useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Logo from "../../components/Logo"
import MenuLink from "../../components/MenuLink";
import Typography from "@mui/material/Typography";
import pixelsToRem from "../../styles/Themes/core/PixelsToRem";
import darkTheme from "../../styles/Themes/DarkTheme";

const menuItems = [
    { path: "home", name: "Company" },
    { path: "features", name: "Services" },
    { path: "technology", name: "Technology" },
    { path: "tokendetails", name: "Token Details" },
    { path: "saletoken", name: "Sale Token" },
    { path: "nft", name: "NFT" },
];

// ------------------------------------

const RootStyle = styled(AppBar)({
  boxShadow: "none",
  backgroundColor: darkTheme.palette.background.default,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
});

const ButtonStyle = styled("a")({
    backgroundColor: "transparent",
    border: "1px solid white",
    boxShadow: "none",
    fontSize: "1rem",
    cursor: "pointer",
    color: "white",
    lineHeight: 1,
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: "0",
    textTransform: "capitalize",
    textDecoration: 'none',
"&:hover, &:focus": {
    background: "gray",
    color: "#fff",
  },
});

const Navigation = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const matches = useMediaQuery('(min-width:1200px)');


    const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };
  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <RootStyle position="fixed">
      <Container maxWidth="2xl">
        <Toolbar
          sx={{
            display: "flex",
            color: "#000",
            py: 1,
            pt: 2,
              pb: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Logo />
            {matches ?
                (
                    <>
                        {/* Menu Items */}
                        <Box
                            sx={{
                                display: {
                                    xs: "none", sm: "none",
                                    md: "flex",
                                },
                                mx: "auto",
                            }}
                        >
                            {menuItems.map((item, i) => (
                                <MenuLink
                                    key={i}
                                    label={item.name}
                                    path={item.path}
                                    style={{ marginRight: "0px" }}
                                />
                            ))}
                        </Box>

                        {/* navbar button */}
                        <Box
                            sx={{
                                justifyContent: "flex-end",
                                display: {
                                    xs: "none",
                                    sm: "none",
                                    md: "flex",
                                },
                            }}
                        >
                            <ButtonStyle
                                href={"https://basedfinance.io"}
                            >
                                <Typography variant={'body1'}
                                            sx={{fontSize: pixelsToRem(15)}}>
                                    Launch App
                                </Typography>
                            </ButtonStyle>
                        </Box>

                    </>
                ) : (
                    <>
                        {/* mobile menu button */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                justifyContent: "flex-end",
                                display: 'flex'
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenSidebar}
                                color="primary"
                                style={{ fontWeight: "900", fontSize: "2.5rem" }}
                            >
                                <Icon icon="eva:menu-2-fill" />
                            </IconButton>
                        </Box>

                        {/* mobile drawer */}
                        <Drawer
                            open={sidebarOpen}
                            onClose={handleCloseSidebar}
                            PaperProps={{
                                 width: 280, padding: "2rem",
                                style: {
                                    backgroundColor: 'rgba(0,0,0,0.95)',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{ flexGrow: 1 }} />
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleCloseSidebar}
                                    color="inherit"
                                >
                                    <Icon icon="mdi:close" />
                                </IconButton>
                            </Box>
                            <Box ml={2} mr={2}  sx={{ display: "flex", alignItems: "center" }}>
                                <Logo />
                            </Box>
                            <Box
                                sx={{
                                    mt: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    mr: 2,
                                    ml: 2
                                }}
                            >
                                {menuItems.map((item, i) => (
                                    <MenuLink
                                        key={i}
                                        path={item.path}
                                        onClick={handleCloseSidebar}
                                        style={{
                                            marginBottom: "1.25rem",
                                            border: '1px solid white'
                                        }}
                                        label={item.name}
                                    />
                                ))}

                                <ButtonStyle
                                    href={"https://basedfinance.io"}
                                    sx={{
                                        mt: 5,
                                        textAlign: 'center'
                                    }}
                                >
                                    <Typography variant={'body1'}
                                                sx={{fontSize: pixelsToRem(15)}}>
                                        Launch App
                                    </Typography>
                                </ButtonStyle>
                            </Box >
                        </Drawer>
                    </>)
            }
        </Toolbar>
      </Container>
    </RootStyle>
  );
};

export default Navigation;
