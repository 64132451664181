import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Box, Typography } from "@mui/material";
import ContactsLink from "../../components/ContactsLink";

const Tg = "/static/telegram.svg";
const Twitter = "/static/twitter.svg";
const Discord = "/static/discord.svg";
const Github = "/static/github.svg";

const RootStyle = styled("div")({
  background: "#000",
});

const Footer = () => {
  return (
    <RootStyle>
      <Container>
        <Box
          sx={{
            display: "flex",
            placeItems: "center",
            padding: "1.25rem 1rem",
          }}
          justifyContent={'space-between'}
        >
          <Typography color={'primary'} sx={
              {
                  fontWeight: '800'
              }
          }>
              Copyright &copy; Based Labs {new Date().getFullYear()}
          </Typography>
            <Box display={'flex'} ml={2}>
                <ContactsLink path={'https://twitter.com/BasedFinance_io'} imgPath={Twitter}></ContactsLink>
                <ContactsLink path={'https://github.com/BasedFinance'} imgPath={Github}></ContactsLink>
                <ContactsLink path={'https://t.me/BasedFinanceio'} imgPath={Tg}></ContactsLink>
                <ContactsLink path={'https://dsc.gg/based-finance'} imgPath={Discord}></ContactsLink>
            </Box>
        </Box>
      </Container>
    </RootStyle>
  );
};

export default Footer;
