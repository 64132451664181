import { Box, Grid, Typography } from "@mui/material";
import {styled} from "@mui/material/styles";

const styles = {
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pb: 2,
    pt: 2,
    margin: '0 auto'
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: 1.25,
    ml: 2,
    mr: 2,
    mt: 2,
    display: 'block'
  },
  text: {
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: 1.2,
    ml: 2,
    margin: '2 auto',
    display: 'block',
    mb: 2,
    mr: 2
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  background: "#33343B",
  width: '100%',
  margin: '0 auto',
  height: '100%',
  minHeight: '176px',
}));

const Feature = ({ item }) => (
  <Grid item sm={12} md={6} lg={6} sx={styles.grid}>
    <StyledBox display={'flex'} rowDirection={'row'} alignItems={'center'} justifyContent={'center'}>
      <Box alignItems={'center'} justifyContent={'center'} sx={{ml:'40px'}}>
        <img src={item.imgSrc} width={'96px'} height={'96px'} />
      </Box>
      <Box>
        <Typography variant="h3" color={'primary'} sx={styles.title} gutterBottom>
          {item.title}
        </Typography>
        <Typography variant="p" color={'secondary'} sx={styles.text}>
          {item.text}
        </Typography>
      </Box>
    </StyledBox>
  </Grid>
);

export default Feature;
