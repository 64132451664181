import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {styled} from "@mui/material/styles";

const styles = {
    grid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 2,
        pt: 2,
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.25,
        display: 'block',
        ml: '5px',
        mr: '5px'
    },
    text: {
        fontWeight: 500,
        lineHeight: 1.2,
        mr: 2,
        ml: 2,
        mb: 2,
        display: 'block'
    },
};

const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
     width: '100%',
    margin: '0 auto ',
    minHeight: '163px',
    maxHeight: '163px',
    alignItems: 'center',
    textAlign: 'center',
}));

const TextBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    background: "#33343B",
    maxWidth: '176px',
    minWidth: '176px',
    margin: '20px 20px 20px 20px',
    minHeight: '163px',
    maxHeight: '163px',
    alignItems: 'center',
    textAlign: 'center'
}));

const IconBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    background: "#33343B",
    margin: '0 auto',
    width: '22px',
    height: '22px',
    minWidth: '22px',
    outline: '2px solid #48DCA8'
}));

const SaleTokenItem = ({ item }) => {
    const matches = useMediaQuery('(min-width:1200px)');

    return (
        <Grid item sm={12} md={12} lg={matches ? 2 : 12}
              sx={styles.grid}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{border: '0px solid red', marginTop: matches ? '' : '10px'}}>
            <Box display={ matches ? '' : 'flex'}
                 alignItems={'center'}
                 sx={{width: matches ? '100%' : '200px'}}>
                <IconBox alignItems={'center'} justifyContent={'center'} display={'flex'} sx={{marginTop: matches ? '' : '40px'}}>
                    {/*<img src={item.imgSrc} height={ matches ? '100%' : '20px'} width={'auto'}*/}
                    {/*     sx={{border: '0px solid red', textAlign: 'center', margin: matches ? '' : '0 auto'}}/>*/}
                </IconBox>
                <StyledBox>
                    <TextBox>
                        <Typography variant="h5" color={'primary'} pt={'20px'} sx={styles.title} gutterBottom>
                            {item.title}
                        </Typography>
                        <Typography variant="body2" color={'secondary'} mt={'20px'} sx={styles.text}>
                            {item.text}
                        </Typography>
                    </TextBox>
                </StyledBox>
            </Box>
        </Grid>
    );

};


export default SaleTokenItem;
