import React from "react";
import { Container, Grid, Typography, useMediaQuery, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SectionHeader from "../../components/SectionHeader";

import { motion } from "framer-motion";
import useCustomAnimation from "../hooks/useCustomAnimation";
import SaleTokenItem from "../../components/SaleTokenItem";
import theme from "../../styles/Themes/DarkTheme";

///////////////////////////////////////
const Elipse = "/static/features/ellipse-18.svg";


const data = [
    {
        id: 1,
        imgSrc: Elipse,
        altText: "Start",
        title: "Start   ",
        text: "Feb 8, 2018 (9:00AM GMT)",
    },
    {
        id: 2,
        imgSrc: Elipse,
        altText: "End",
        title: "End",
        text: "Feb 8, 2018 (9:00AM GMT)",
    },
    {
        id: 3,
        imgSrc: Elipse,
        altText: "Acceptable curencies",
        title: "Acceptable curencies",
        text: "ETH, BTC, LTC",
    },
    {
        id: 4,
        imgSrc: Elipse,
        altText: "Munber of tokens for sale",
        title: "Munber of tokens for sale",
        text: "900.0000 ICC (9%)",
    },
    {
        id: 5,
        imgSrc: Elipse,
        altText: "Tokens exchange rate",
        title: "Tokens exchange rate",
        text: "1 ETH = 650 ICC, 1 BTC = 1940 ICC",
    },
    // {
    //     id: 6,
    //     imgSrc: Elipse,
    //     altText: "Minimal transection amount",
    //     title: "Minimal transection amount",
    //     text: "1 ETH / 1 BTC / LTC",
    // },
];

const RootStyle = styled("section")({
    paddingBottom: "5rem",
});

const SepparatorDiv = styled("div")({
    height: '2px',
    width: '100px',
    background: '#48DCA8',
    margin: '0 auto'
});

const DashedLine = styled("div")(({ theme }) => ({
    width: '66%',
    border: '2px dashed gray',
    margin: '60px auto',
}));

const DashedLineVertical = styled(Box)(({ theme }) => ({
    border: '2px dashed gray',
    width: '0px',
    display: 'flex',
    marginTop: '36px',
    height: '700px',
}));


const SaleTokens = () => {
    const { ref, fadeUp } = useCustomAnimation(0.0);
    const matches = useMediaQuery('(min-width:1200px)');
    console.log('matches', matches)
    return (
        <RootStyle id="saletoken">
            <Container maxWidth="2xl"  ref={ref} sx={{maxWidth: '1360px', textAlign: 'center'}}>
                <SectionHeader title="Sale token" />
                <SepparatorDiv></SepparatorDiv>
                <Box display={  'flex' }
                     flexDirection={matches ? 'column' : 'row'}
                     alignItems={'center'}
                     justifyContent={'center'}
                     sx={{border:'0px solid blue' , textAlign: 'center',
                         margin: matches ? '' : ' auto auto',
                         width: matches ? '100%' : '200px'}}>
                    { matches ? (
                        <>
                            <DashedLine></DashedLine>
                        </>
                          ) : (
                             <DashedLineVertical></DashedLineVertical>
                        )  }
                    <Grid container
                          alignItems={'center'}
                          justifyContent={'center'}
                          // spacing={2}
                          display={'flex'}
                          component={motion.div}
                          sx={{ marginTop: matches ?  '-74px' : '0px' , marginLeft: matches ? '' : '-13px', width:'100%'}}>
                        {data?.map((item) => (
                            <SaleTokenItem key={item.id} item={item} />
                        ))}
                    </Grid>
                </Box>

            </Container>
        </RootStyle>
    );
};

export default SaleTokens;
