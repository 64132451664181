import * as React from 'react';

import DarkTheme from "./styles/Themes/DarkTheme";
import { ThemeProvider} from "@mui/material";

import { Navigate, useRoutes } from "react-router-dom";

import MainLayout from "./onepager/layout";
import Page404 from "./onepager/pages/Page404";
import Home from "./onepager/pages/Home";
import { BrowserRouter } from "react-router-dom";



const Router = () => {
    return useRoutes([
        {
            path: "/",
            element: <MainLayout />,
            children: [
                { path: "/", element: <Navigate to="/home" /> },
                { path: "home", element: <Home /> },
                { path: "404", element: <Page404 /> },
                { path: "*", element: <Navigate to="/404" /> },
            ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
};


export default function App() {
    return (
        <>
            <BrowserRouter>
                <ThemeProvider theme={DarkTheme}>
                    <Router />
                </ThemeProvider>
            </BrowserRouter>
        </>
    );
}
