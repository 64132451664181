import {Typography, Box, responsiveFontSizes, ThemeProvider} from "@mui/material";
import { Link } from "react-router-dom";
import DarkTheme from "../styles/Themes/DarkTheme";

const Logo = () => {
    let theme = responsiveFontSizes(DarkTheme);

    return (
        <ThemeProvider theme={theme}>
            <Typography
                variant="h3"
                noWrap
                component={Link}
                to="/"
                sx={{
                    fontWeight: "700",
                    lineHeight: 1.4,
                    letterSpacing: "0.2rem",
                    textDecoration: "none",
                    color: "gray",
                }}
            >
                BASEDLABS
            </Typography>
        </ThemeProvider>
  );
};

export default Logo;
