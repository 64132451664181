import {
  Container,
  useMediaQuery,
  Typography,
  Box,
    Grid
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import pixelsToRem from "../../styles/Themes/core/PixelsToRem";
const Bg = "/static/bg1.svg";


const RootBgStyle = styled("div")(({ theme }) => ({
    position: "absolute",
    backgroundImage: "url(" + Bg + ")",
    zIndex: -1,
    minHeight: "65vh",
    placeItems: "center",
    width: '25vw',
    transform: "matrix(1, 2, -1, 1, 80, 80)",
    [theme.breakpoints.down("md")]: {
        display: 'none',
    },
}));

const RootStyle = styled("section")(({ theme }) => ({
    minHeight: "70vh",
    display: "grid",
    placeItems: "center",
    margin: '0 auto',
    width: '80%',
    paddingTop: "10rem",
    paddingBottom: '5rem',
    [theme.breakpoints.down("md")]: {
        paddingTop: "5rem",
    },
}));

const ImgContainer = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
    background: "#33343B",
    width: '85%',
    margin: '0 auto',
    maxWidth: '420px',
    padding: '10px',
    textAlign: 'center',
    [theme.breakpoints.up("xs")]: {
    marginTop: "60px",
    },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
   alignItems: "center",
  border:'0px solid red',
    maxWidth: '520px',
     margin: '0 auto',
    height: '100%',
    padding: '10px',
}));


const Home = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // animation easing
  let easing = [0.6, -0.05, 0.01, 0.99];

  return (
      <>
      <RootBgStyle>
      </RootBgStyle>
          <RootStyle id="home">
              <Container maxWidth="xl" >
                  <Box
                      sx={{
                          mb: 5, display: 'flex',
                          border: '0px solid red',
                      }}
                  >
                      <Grid container alignItems={'center'} justifyContent={'center'}  >
                          <Grid item md={6} sm={12} xs={12} alignItems={'center'} justifyContent={'center'} sx={{border: '0px solid blue'}}>
                              <TextContainer transition={{ staggerChildren: 0.25 }}>
                                  <Typography
                                      initial={{ y: 60, opacity: 0 }}
                                      transition={{ duration: 0.6, ease: easing }}
                                      animate={{ y: 0, opacity: 1 }}
                                      variant="h1"
                                      color={"primary"}
                                      component={motion.h1}
                                      sx={{
                                          fontSize: { md: pixelsToRem(48), xs: "2rem" },
                                          fontWeight: "700",
                                          lineHeight: 1.25,
                                      }}
                                      gutterBottom
                                  >
                                      Powering Data for the new equity blockchain.
                                  </Typography>
                                  <Typography
                                      initial={{ y: 60, opacity: 0 }}
                                      transition={{ duration: 0.6, ease: easing, delay: 0.5 }}
                                      animate={{ y: 0, opacity: 1 }}
                                      variant="p"
                                      color={"secondary"}
                                      component={motion.p}
                                      style={{
                                          fontSize: "19px",
                                          // paddingRight: `${mdUp ? "50px" : 0}`,
                                          lineHeight: 1.4,
                                          fontWeight: 400,
                                      }}
                                      gutterBottom
                                  >
                                      The platform helps investors to make easy to purchase and sell their tokens
                                  </Typography>
                              </TextContainer>
                          </Grid>
                          <Grid item md={6} sm={12} xs={12} alignItems={'center'} justifyContent={'center'} sx={{border: '0px solid blue'}}>
                              <ImgContainer
                                  initial={{ x: 60, opacity: 0 }}
                                  transition={{ duration: 0.6, ease: easing }}
                                  animate={{ x: 0, opacity: 1 }}
                                  component={motion.div}
                              >
                                  <Typography
                                      initial={{ y: 60, opacity: 0 }}
                                      transition={{ duration: 0.6, ease: easing }}
                                      animate={{ y: 0, opacity: 1 }}
                                      variant="h1"
                                      color={"primary"}
                                      component={motion.h1}
                                      sx={{
                                          fontSize: { md: pixelsToRem(35), xs: "2rem" },
                                          fontWeight: "700",
                                          lineHeight: 1.25,
                                          textAlign: 'center'
                                      }}
                                      gutterBottom
                                  >
                                      Total Value Locked
                                  </Typography>
                                  <Typography
                                      initial={{ y: 60, opacity: 0 }}
                                      transition={{ duration: 0.6, ease: easing }}
                                      animate={{ y: 0, opacity: 1 }}
                                      variant="h1"
                                      color={"primary"}
                                      component={motion.h1}
                                      sx={{
                                          fontSize: { md: pixelsToRem(35), xs: "2rem" },
                                          fontWeight: "700",
                                          lineHeight: 1.25,
                                          textAlign: 'center'
                                      }}

                                  >
                                      58,456,567,34
                                  </Typography>
                                  {/*<ImgStyle src="/static/hero.png" alt="hero thumb" />*/}
                              </ImgContainer>
                          </Grid>
                      </Grid>
                  </Box>
              </Container>
          </RootStyle>
      </>

  );
};

export default Home;
