import React from "react";
import {Features, Hero, NftBlock, SaleTokens, Technology, TokensDetails} from "../sections";

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
        <Technology/>
        <TokensDetails/>
        <SaleTokens/>
        <NftBlock/>
    </>
  );
};

export default Home;
