import { Box, Grid, Typography } from "@mui/material";
import {styled} from "@mui/material/styles";

const styles = {
    grid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 2,
        pt: 2,
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.25,
        ml: 2,
        display: 'block'
    },
    text: {
        fontWeight: 400,
        lineHeight: 1.4,
        ml: 2,
        display: 'block'
    },
};

const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '20px',
     background: "#33343B",
    maxWidth: '300px',
    width:'100%',
    overflow: 'clip',
    minHeight: '391px',
    height: '100%'
}));

const IconBox = styled(Box)(({ theme }) => ({
    background: "gray",
    margin: '0 auto',
    width: '300px',
    height: '263px',
}));

const SepparatorDiv = styled("div")({
    marginTop: '5px',
    height: '2px',
    maxHeight: '2px',
    width: '100px',
    border: '1px solid #48DCA8',
    margin: '5px 20px auto',
    marginBottom: '10px'
});

const NftItem = ({ item }) => (
    <Grid item sm={12} md={4} lg={3} sx={styles.grid}>
        <StyledBox  >
            <IconBox alignItems={'center'} justifyContent={'center'} sx={{ml:0, mt:'0px'}} display={'flex'}>
                <img src={item.imgSrc}  height={'100%'} width={'100%'} sx={{border: '1px solid red', textAlign: 'center'}}/>
            </IconBox>
            <Box>
                <Typography variant="h5" color={'primary'} mt={'20px'} sx={styles.title} gutterBottom>
                    {item.title}
                </Typography>
                <Typography variant="h6" color={'secondary'} mt={'20px'} sx={styles.text}>
                    {item.text}
                </Typography>
                <SepparatorDiv></SepparatorDiv>
            </Box>
        </StyledBox>
    </Grid>
);

export default NftItem;
