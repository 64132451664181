import { createTheme } from "@mui/material/styles";


import typography from "./core/dark/Typography";
import colors from "./core/dark/Colors";
import breakpoints from "./core/Breakpoints";
const Dark = createTheme({
    palette: { ...colors },
    typography: { ...typography },
    breakpoints: { ...breakpoints }
});

const DarkTheme = {
    ...Dark,
}

export default DarkTheme;